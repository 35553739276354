import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
import { LinkArrowSimple, Intro } from '~/src/components/Typography';
export const frontmatter = {
  title: 'Security',
  header: 'File uploading, storage, processing, and delivery — secure by design',
  description: 'File uploading, storage, processing, and delivery — secure by design'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Intro mdxType="Intro">
  We understand that security and privacy are vital when handling files and user
  data. While the default Uploadcare setup is secure by default, there are
  advanced features that give you even more control over your content.
    </Intro>
    <hr></hr>
    <h2 {...{
      "id": "control-uploads-and-access-to-the-data"
    }}><a parentName="h2" {...{
        "href": "#control-uploads-and-access-to-the-data"
      }}>{`Control uploads and access to the data`}</a></h2>
    <h3 {...{
      "id": "secure-uploading"
    }}><a parentName="h3" {...{
        "href": "#secure-uploading"
      }}>{`Secure uploading`}</a></h3>
    <p>{`Allow incoming traffic by authorized users only. A time-limited token is
required along with the upload request.`}</p>
    <LinkArrowSimple to='/docs/security/secure-uploads/' mdxType="LinkArrowSimple">Signed
uploads</LinkArrowSimple>
    <h3 {...{
      "id": "virus-checking"
    }}><a parentName="h3" {...{
        "href": "#virus-checking"
      }}>{`Virus checking`}</a></h3>
    <p>{`Enable malware checking for all uploaded files.`}</p>
    <LinkArrowSimple to='/docs/security/malware-protection/' mdxType="LinkArrowSimple">Malware
Protection</LinkArrowSimple>
    <h3 {...{
      "id": "unsafe-content-detection"
    }}><a parentName="h3" {...{
        "href": "#unsafe-content-detection"
      }}>{`Unsafe content detection`}</a></h3>
    <p>{`Detect and identify inappropriate, unwanted, NSFW, or offensive user-generated
content in order to enhance user safety, ensure brand integrity, and comply
with local and global regulations.`}</p>
    <LinkArrowSimple to='/docs/unsafe-content/' mdxType="LinkArrowSimple">Unsafe content
deteclion</LinkArrowSimple>
    <h3 {...{
      "id": "secure-delivery"
    }}><a parentName="h3" {...{
        "href": "#secure-delivery"
      }}>{`Secure delivery`}</a></h3>
    <p>{`Create secure links with expiration that can be opened with a time-limited
signature only, generated on a backend.`}</p>
    <LinkArrowSimple to='/docs/security/secure-delivery/' mdxType="LinkArrowSimple">Signed
URLs</LinkArrowSimple>
    <h3 {...{
      "id": "search-engines-indexing-behavior"
    }}><a parentName="h3" {...{
        "href": "#search-engines-indexing-behavior"
      }}>{`Search engines indexing behavior`}</a></h3>
    <p>{`Restrict search engines to analyze and index content in your project for global
search: image and text.`}</p>
    <LinkArrowSimple to='/docs/delivery/cdn/#search-engine-indexing' mdxType="LinkArrowSimple">Search
Engine Indexing</LinkArrowSimple>
    <h3 {...{
      "id": "ddos-protection"
    }}><a parentName="h3" {...{
        "href": "#ddos-protection"
      }}>{`DDoS protection`}</a></h3>
    <p>{`Customers are automatically protected against Distributed Denial of Service (DDoS).`}</p>
    <h2 {...{
      "id": "make-it-yours"
    }}><a parentName="h2" {...{
        "href": "#make-it-yours"
      }}>{`Make it yours`}</a></h2>
    <h3 {...{
      "id": "custom-cdn-cname"
    }}><a parentName="h3" {...{
        "href": "#custom-cdn-cname"
      }}>{`Custom CDN CNAME`}</a></h3>
    <p>{`Use your own domain for CDN links to your files stored with Uploadcare (e.g.,
cdn.mycompany.com instead of ucarecdn.com). It avoids file delivery issues
caused by an overzealous VPN/Firewall and hides Uploadcare name for end users.`}</p>
    <LinkArrowSimple to='/docs/delivery/cdn/#custom-cdn-cname' mdxType="LinkArrowSimple">Learn more</LinkArrowSimple>
    <h3 {...{
      "id": "custom-oauth"
    }}><a parentName="h3" {...{
        "href": "#custom-oauth"
      }}>{`Custom OAuth`}</a></h3>
    <p>{`Ask your users to give permissions to your company, not Uploadcare, when they
upload files from cloud sources. End users might not know about Uploadcare and
it'll remove users' confusion.`}</p>
    <LinkArrowSimple to='/docs/start/settings/#project-settings-advanced-oauth' mdxType="LinkArrowSimple">Learn more</LinkArrowSimple>
    <h3 {...{
      "id": "custom-storage"
    }}><a parentName="h3" {...{
        "href": "#custom-storage"
      }}>{`Custom storage`}</a></h3>
    <p>{`We provide four storage options: keep it on our smart storage, back it up
automatically to your S3, copy it to S3 on-demand, or upload it directly to a
completely foreign bucket.`}</p>
    <LinkArrowSimple to='/docs/uploads/storage/' mdxType="LinkArrowSimple">Learn more</LinkArrowSimple>
    <h3 {...{
      "id": "custom-uploading-cnames"
    }}><a parentName="h3" {...{
        "href": "#custom-uploading-cnames"
      }}>{`Custom Uploading CNAMEs`}</a></h3>
    <p>{`Upload and social CNAMEs can be used to replace the default upload and social
API endpoints. It avoids file uploading problems behind an overzealous
VPN/Firewall and hides Uploadcare name for end users.`}</p>
    <h2 {...{
      "id": "built-in-security--compliance"
    }}><a parentName="h2" {...{
        "href": "#built-in-security--compliance"
      }}>{`Built-in security & compliance`}</a></h2>
    <p>{`For purposes of keeping customer and end-user data safe and secure, Uploadcare
is committed to complying with industry-standard privacy and security measures,
as well as with all applicable data privacy and security laws and regulations.`}</p>
    <LinkArrowSimple to='/about/trust/' mdxType="LinkArrowSimple">Trust Center</LinkArrowSimple>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      